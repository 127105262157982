import * as React from 'react'
import { Helmet } from 'react-helmet-async'

const Wrapper = ({ children }) => (
  <>
    <Helmet>
      <style>
        {`
          code {
            background: #eee;
            color: black;
            padding: 2px 8px;
          }

          .prism-code .token.comment {
            white-space: initial;
          }
        `}
      </style>
    </Helmet>
    {children}
  </>
)

export default Wrapper
